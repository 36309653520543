<template>
    <b-form-group id="simple-beneficiary-select" class="form-group">
        <label>Selecione um favorecido</label>
        <multiselect
            id="beneficiary"
            label="name"
            track-by="id"
            v-model="beneficiary"
            :options="options"
            :showLabels="false"
            :searchable="true"
            :allowEmpty="true"
            placeholder="Selecionar"
            class="with-border"
        >
            <template slot="caret">
                <div class="chevron">
                    <ChevronDown />
                </div>
            </template>
            <template slot="noOptions"> Nenhuma opção </template>
            <template slot="noResult"> Nenhum resultado </template>
        </multiselect>
    </b-form-group>
</template>
<script>

export default {
    props: {
        value: String,
        validated: Boolean,
        options: Array
    },
    components: {
        ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    },
    data() {
        return {
            beneficiary: null
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(value) {
                this.beneficiary = this.options.find(option => option.value === value)
            }
        },
        beneficiary(option) {
            this.$emit('input', option)
            this.$emit('change', option)
        }
    }
}
</script>
<style lang="scss" scoped>
#simple-beneficiary-select {
    min-width: 300px !important;
}
</style>